import React from 'react'

function RegisteredInput({inputMode, inputValue, register, name, onInput, errors, ...rest}) {
    return (
    <>
        {onInput ? (
            <input 
                inputMode={inputMode || "text"}
                value={inputValue}
                {...register}
                onChange={onInput}
                style={{ borderColor: (errors && errors[name]) ? '#da2972' : null }}
                {...rest}
            />
        ): (
            <input 
                inputMode={inputMode || "text"}
                value={inputValue}
                onChange={onInput}
                {...register}
                style={{ borderColor: (errors && errors[name]) ? '#da2972' : null }}
                {...rest}
            />
        )}
    </>
  )
}

export default RegisteredInput