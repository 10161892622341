import React, { useCallback } from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { MainDescription } from '../../Typography';
import InputWithValidation from './InputWithValidation';

function CustomTextField({
  label,
  title,
  name,
  initialValue,
  reversed,
  register,
  validationOptions,
  errors,
  handleInput,
  setValue
}) {
  return (
    <div className="custom-input">
      <MainDescription>{title}</MainDescription>
      <div
        className={`custom-input__container ${reversed ? 'custom-input__container-reversed' : ''}`}
      >
        <div className="custom-input-label__container">
          <p className="custom-input-label">{label}</p>
        </div>
        <InputWithValidation 
          inputMode="numeric"
          register={register}
          name={name}
          errors={errors}
          handleInput={handleInput}
          initialValue={initialValue}
          setValue={setValue}
          validationOptions={validationOptions}
        />
      </div>
      {(errors && errors[name]?.message) && (
        <p className="custom-input-error">{errors[name]?.message}</p>
      )}
    </div>
  );
}

export default CustomTextField;
