import React, { useState } from 'react';
import Heading from '../../Heading';
import CustomHeadingSection from '../CustomHeadingSection';
import SectionWrapper from '../SectionWrapper';
import CustomTextField from '../formElements/CustomTextField';
import RadioButtonGroup from '../formElements/RadioButtonGroup';
import circularProgressInside from '../../../assets/images/illustrations/RemortgagePage/circular-progress-inside.png';
import TriangularArrowDown from '../../../assets/images/icons/other/triangular-arrow-down.inline.svg';
import { SecondaryTitle } from '../../Typography';
import { ControlledMenu, MenuItem, useClick } from '@szhsin/react-menu';
import Button from '../../Button';
import useWindow from '../../../hooks/useWindow';
import TextDropDown from '../../TextDropDown';
import { useForm } from 'react-hook-form';
import { useRef } from 'react';
import { useCallback } from 'react';
import { SPRIVE_API_BASE_URL, SPRIVE_API_KEY } from '../../../constants';
import { navigate } from 'gatsby';
import { useEffect } from 'react';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import useLenders from '../../../hooks/useLenders';
import CustomImage from '../../Image/Image';
import loadable from '@loadable/component';

const ModalsFlow = loadable(() => import("../../Modals/ModalsFlow"))

const firstRadioGroupItems = [
  {
    label: 'I live in the property',
    value: "Live",
    id: 'live-radio',
  },
  {
    label: 'I rent the property out',
    value: "Rent",
    id: 'rent-radio',
  },
];
const secondRadioGroupItems = [
  {
    label: "It's already ended",
    value: 'Its already ended',
    id: 'already-ended-radio',
  },
  {
    label: 'Within 6 months',
    value: 'Within 6 Months',
    id: 'six-month-radio',
  },
  {
    label: 'In more than 6 months',
    value: 'More than 6 Months',
    id: 'more-six-month-radio',
  },
];
const thirdRadioGroupItems = [
  {
    label: 'England',
    value: 'England',
    id: 'england-radio',
  },
  {
    label: 'Scotland',
    value: 'Scotland',
    id: 'scotland-radio',
  },
  {
    label: 'Wales',
    value: 'Wales',
    id: 'wales-radio',
  },
  {
    label: 'Northern Ireland',
    value: 'Northern Ireland',
    id: 'northern-ireland-radio',
  },
];

const assumptions = (
  <>
    - The mortgage advisor we connect you may recommend deals that are not listed on the Sprive website
    <br />
    <br />
    - Deals get refreshed daily, so a product we show you may not be 
    available by the time our expert submits your application
  </>
)

function MortgageDetailsSection({ withoutHeading, customFormHandler, prefill }) {
  const { isMobile } = useWindow();
  const [prefilledValues, setPrefilledValues] = useState(null)
  const [dropdownValue, setDropdownValue] = useState({
    value: 'Select an option',
    id: -1,
  });
  const [dropdownError, setDropdownError] = useState(false);
  const [dropdownFilter, setDropDownFilter] = useState('');
  const [homeOwnershipData, setHomeOwnershipData] = useState({
    leftToPay: 0,
    leftYears: 0,
    propertyWorth: 0,
  });
  const [ownershipPercent, setOwnershipPercent] = useState(0);
  const [formData, setFormData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dropdownButtonRef = useRef(null);
  const dropdownInputRef = useRef(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isDropdownOpenWithDelay, setDropdownOpenWithDelay] = useState(false);
  const anchorProps = useClick(isDropdownOpen, setDropdownOpen);
  const { lenders } = useLenders()


  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({mode: "onChange"});
  const onSubmit = useCallback(
    (data) => {
      if (!checkDropdown()) {
        return;
      }

      const uuid = localStorage.getItem('uuid');
      setIsLoading(true);

      if (withoutHeading || uuid) {
        const userData = {
          name: localStorage.getItem('name'),
          email: localStorage.getItem('email'),
          phone: localStorage.getItem('phone'),
        };

        postUserQuery(userData, data);
        return;
      }

      setIsModalOpen(true);
      setFormData(data);
      setIsLoading(false);
    },
    [dropdownValue]
  );

  const checkDropdown = useCallback(() => {
    if (dropdownValue.id === -1) {
      setDropdownError(true);

      return false;
    }

    return true;
  }, [dropdownValue]);

  const validateRadio = useCallback((value, radioGroup) => {
    for (let item of radioGroup) {
      if (item.label === value) {
        return item.value;
      }
    }
  }, []);

  const replaceCommas = useCallback((value) => {
    if(!value?.replace) return value
    return parseInt(value.replace(/,/g, ''));
  });

  const postUserQuery = useCallback(
    (userData, customFormData) => {
      const remortgageData = customFormData || formData;

      if (!remortgageData || !userData) return;

      const requestBody = {
        borrow_amount: replaceCommas(remortgageData['leftPay']),
        borrow_year: replaceCommas(remortgageData['leftYears']),
        home_valuation: replaceCommas(remortgageData['propertyWorth']),
        beneficiary_id: dropdownValue.id,
        property_type: validateRadio(remortgageData['first-group'], firstRadioGroupItems) || prefilledValues.property_type,
        initial_deal_expires: validateRadio(remortgageData['second-group'], secondRadioGroupItems) || prefilledValues.initial_deal_expires,
        property_location: remortgageData['third-group'] || prefilledValues.property_location,
        user: {
          email: userData.email,
          name: userData.name,
          contact_no: userData.phone,
        },
      };

      fetch(SPRIVE_API_BASE_URL + 'product-selector-search-preference', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          api_key: SPRIVE_API_KEY,
        },
        body: JSON.stringify(requestBody),
      })
        .then((data) => data.json())
        .then((data) => {
          if (data.success) {
            localStorage.setItem('uuid', data?.data?.uuid);

            if (customFormHandler) {
              customFormHandler();
            }
            localStorage.setItem("mortgageData", JSON.stringify({
              ...requestBody,
              dropdownValue: dropdownValue.value
            }))
            navigate('/view-deals');
          }
        });
    },
    [formData, customFormHandler, dropdownValue, prefilledValues]
  );

  const generateDropdownOptions = useCallback(() => {
    if (!lenders) {
      return;
    }

    const components = [];

    lenders.forEach((item, index) => {
      if (!item.company_name.toLowerCase().includes(dropdownFilter.toLowerCase())) return;
      components.push(
        <MenuItem
          key={index}
          onClick={() => {
            setDropdownError(false);
            setDropdownValue({
              value: item?.company_name,
              id: item?.id,
            });
          }}
        >
          {item?.company_name}
        </MenuItem>
      );
    });

    return components;
  }, [lenders, dropdownFilter]);

  const calculateHomeOwnership = useCallback(
    (newState) => {
      const { leftToPay, propertyWorth } = newState;
      const homeOwnerShipPercent = 100 - (leftToPay / propertyWorth) * 100;

      setOwnershipPercent(Math.max(Math.round(homeOwnerShipPercent), 0));
    },
    [homeOwnershipData]
  );

  const handleInput = useCallback(
    (value, inputType) => {
      const newState = {
        ...homeOwnershipData,
        [inputType]: value,
      };

      setHomeOwnershipData(newState);

      for (let item of Object.values(newState)) {
        if (!item) return;
      }

      calculateHomeOwnership(newState);
    },
    [homeOwnershipData]
  );

  useEffect(() => {
    const handleClick = (e) => {
      if (e.target !== dropdownInputRef.current && isDropdownOpenWithDelay) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [isDropdownOpenWithDelay]);

  useEffect(() => {
    setTimeout(() => {
      setDropdownOpenWithDelay(isDropdownOpen);
    }, 200);
  }, [isDropdownOpen]);

  useEffect(() => {
    const mortgageData = localStorage.getItem("mortgageData")

    if(!mortgageData || !prefill) return
    const parsedMortgageData = JSON.parse(mortgageData)
    const homeOwnershipState = {leftToPay: parsedMortgageData.borrow_amount, propertyWorth: parsedMortgageData.home_valuation}
    calculateHomeOwnership(homeOwnershipState)
    setPrefilledValues(parsedMortgageData)
    setDropdownValue({
      value: parsedMortgageData.dropdownValue,
      id: parsedMortgageData.beneficiary_id
    })
    setHomeOwnershipData(homeOwnershipState)
  }, [prefill])

  return (
    <div
      className={
        !withoutHeading ? 'mortgage-details' : 'mortgage-details mortgage-details__no-padding'
      }
      id="mortgage-details"
    >
      <SectionWrapper>
        {!withoutHeading && (
          <Heading
            title={
              <>
                Let’s get started. Tell us about
                {!isMobile ? <br /> : " "}
                your existing mortgage.
              </>
            }
            subtitle="By sharing some information about your current mortgage deal, we’ll be able to show you personalised deals and connect you to a vetted mortgage advisor."
          />
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <CustomHeadingSection
            leftContent={
              <>
                <div className="first-form__container">
                  <CustomTextField
                    label="£"
                    title="How much do you have left to pay on your mortgage?"
                    name="leftPay"
                    register={register}
                    validationOptions={{
                      required: true,
                      validate: (value) => (replaceCommas(value) < 10000000) || "Invalid amount. Please re-enter a value less than £10,000,000"
                    }}
                    errors={errors}
                    initialValue={prefilledValues?.borrow_amount}
                    handleInput={(e) => handleInput(e, 'leftToPay')}
                    setValue={setValue}
                  />
                  <CustomTextField
                    label="Years"
                    title="How many years are left on the mortgage?"
                    name="leftYears"
                    reversed={true}
                    register={register}
                    validationOptions={{
                      required: true,
                      validate: (value) => (replaceCommas(value) <= 40) || "Invalid amount. Please enter a value between 1 and 40 years"
                    }}
                    errors={errors}
                    initialValue={prefilledValues?.borrow_year}
                    handleInput={(e) => handleInput(e, 'leftYears')}
                    setValue={setValue}
                  />
                  <CustomTextField
                    label="£"
                    title="How much is the property worth?"
                    name="propertyWorth"
                    register={register}
                    validationOptions={{
                      required: true,
                      validate: (value) => (replaceCommas(value) < 10000000) || "Invalid amount. Please re-enter a value less than £10,000,000"
                    }}
                    errors={errors}
                    initialValue={prefilledValues?.home_valuation}
                    handleInput={(e) => handleInput(e, 'propertyWorth')}
                    setValue={setValue}
                  />
                </div>
              </>
            }
            rightContent={
              <div className="circular-progress__container">
                <div className="circular-progress">
                  <CircularProgressbarWithChildren
                    value={ownershipPercent}
                    strokeWidth={6.5}
                    styles={buildStyles({
                      pathColor: '#04c1a4',
                      trailColor: '#fff',
                    })}
                  >
                    <div className="circular-progress__image-container">
                      <CustomImage src={circularProgressInside} />
                    </div>
                  </CircularProgressbarWithChildren>
                  <div />
                </div>
                <SecondaryTitle>
                  You own
                  <span>{ownershipPercent}%</span>
                  of your home
                </SecondaryTitle>
              </div>
            }
          />
          {!isMobile && <span className="separator" />}
          <CustomHeadingSection
            leftContent={
              <>
                <div
                  className={
                    !withoutHeading
                      ? 'second-form__container'
                      : 'second-form__container second-form__container__no-padding'
                  }
                >
                  <RadioButtonGroup
                    title="Do you live in the property or rent it out?"
                    items={firstRadioGroupItems}
                    name="first-group"
                    register={register}
                    validationOptions={{
                      required: true,
                    }}
                    initialValue={prefilledValues?.property_type}
                    errors={errors}
                  />
                  <RadioButtonGroup
                    title="When does your existing mortgage come to an end?"
                    items={secondRadioGroupItems}
                    name="second-group"
                    register={register}
                    validationOptions={{
                      required: true,
                    }}
                    initialValue={prefilledValues?.initial_deal_expires}
                    errors={errors}
                  />
                  {(!withoutHeading || isMobile) && (
                    <RadioButtonGroup
                      title="Where is your property?"
                      items={thirdRadioGroupItems}
                      name="third-group"
                      register={register}
                      validationOptions={{
                        required: true,
                      }}
                      initialValue={prefilledValues?.property_location}
                      errors={errors}
                    />
                  )}
                </div>
              </>
            }
            rightContent={
              <div
                className={
                  !withoutHeading
                    ? 'view-details__container'
                    : 'view-details__container view-details__container__no-padding'
                }
              >
                <div
                  className={
                    dropdownError
                      ? 'view-details__dropdown-container view-details__dropdown-container-error'
                      : 'view-details__dropdown-container'
                  }
                >
                  <p>Who is your current mortgage with?</p>
                  <div
                    ref={dropdownButtonRef}
                    {...anchorProps}
                    className={
                      isDropdownOpen ? 'dropdown-button dropdown-button--open' : 'dropdown-button'
                    }
                  >
                    {isDropdownOpen ? (
                      <>
                        <input
                          ref={dropdownInputRef}
                          type="text"
                          placeholder="Type to filter"
                          value={dropdownFilter}
                          onChange={(e) => setDropDownFilter(e.target.value)}
                          autoFocus
                        />
                        <TriangularArrowDown />
                      </>
                    ) : (
                      <>
                        <p>{dropdownValue.value}</p>
                        <TriangularArrowDown />
                      </>
                    )}
                  </div>
                  <ControlledMenu
                    transition
                    direction="bottom"
                    viewScroll="initial"
                    position="initial"
                    state={isDropdownOpen}
                    anchorRef={dropdownButtonRef}
                    onClose={() => {
                      setDropdownOpen(false);
                    }}
                  >
                    <>{generateDropdownOptions()}</>
                  </ControlledMenu>
                </div>
                {!withoutHeading && (
                  <div className="view-details__button-container">
                    {Object.getOwnPropertyNames(errors).length ? (
                      <p className="view-details__error">Please fill in all fields</p>
                    ) : null}
                    <Button
                      buttonText="View Deals"
                      height="66px"
                      submit
                      handleClick={checkDropdown}
                      loading={isLoading}
                    />
                  </div>
                )}
              </div>
            }
          />
          {withoutHeading && !isMobile && (
            <div
              className={
                !withoutHeading
                  ? 'second-form__container'
                  : 'second-form__container second-form__container__no-padding'
              }
              style={{ marginTop: '85px' }}
            >
              <RadioButtonGroup
                title="Where is your property?"
                items={thirdRadioGroupItems}
                name="third-group"
                register={register}
                validationOptions={{
                  required: true,
                }}
                initialValue={prefilledValues?.property_location}
                errors={errors}
              />
            </div>
          )}
          <div className="text-dropdown__container">
            {!withoutHeading ? (
              <TextDropDown
                title="Assumptions"
                description={assumptions}
              />
            ) : (
              <div className="button__container">
                {Object.getOwnPropertyNames(errors).length ? (
                  <p className="view-details__error">Please fill in all fields</p>
                ) : null}
                <Button
                  buttonText="View Deals"
                  height="62px"
                  submit
                  handleClick={checkDropdown}
                  loading={isLoading}
                />
              </div>
            )}
          </div>
        </form>
        {!withoutHeading && (
          <ModalsFlow
            isFirstOpened={isModalOpen}
            setIsFirstOpened={setIsModalOpen}
            showOnlyList={[true, false, false, false]}
            customHandlersList={[postUserQuery]}
            keepModalAfterClick
            dealsContactModal
          />
        )}
      </SectionWrapper>
    </div>
  );
}

export default MortgageDetailsSection;
