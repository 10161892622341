import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { SPRIVE_API_BASE_URL, SPRIVE_API_KEY } from "../constants";

export default function useLenders() {
    const [lenders, setLenders] = useState(null)

    const getLendersList = useCallback(async () => {
        let lendersList = await fetch(`${SPRIVE_API_BASE_URL}payments-beneficiary`, {
          headers: {
            api_key: SPRIVE_API_KEY,
          },
        });
        lendersList = await lendersList.json();

        const sortedLenders = lendersList?.data.sort((a, b) => {
            if(a?.company_name?.toLowerCase() < b?.company_name?.toLowerCase()) {
              return -1
            }

            return 1
        })

        setLenders(sortedLenders);
      }, []);

      useEffect(() => {
        getLendersList();
      }, []);

      return { lenders }
}
