import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useCallback } from 'react';
import { useMemo } from 'react';
import RegisteredInput from './RegisteredInput'

function InputWithValidation({inputMode, register, name, validationOptions, handleInput, errors, initialValue, setValue, ...rest}) {
  const inputRegister = useMemo(() => register && register(name, validationOptions), [name, validationOptions]);
  const [inputValue, setInputValue] = useState('');

  const formatValue = useCallback((value) => {
    return value?.toLocaleString('en-US')
  }, [])

  const onInput = useCallback(
    (e) => {
      const currentValue = parseInt(e.target.value.replace(/,/g, ''));
      if (currentValue || currentValue === 0) {
        setInputValue(formatValue(currentValue));
        if(inputRegister) {
          inputRegister.onChange(e);
        }
      } else if (e.target.value.length === 0) {
        setInputValue('');
        if(inputRegister) {
          inputRegister.onChange(e);
        }
      }
      
      if(handleInput) {
          handleInput(currentValue || 0);
      }
    },
    [inputRegister, handleInput]
  );

  useEffect(() => {
    if(!initialValue) return

    setInputValue(formatValue(initialValue))

    if(setValue) setValue(name, initialValue)
  }, [initialValue])

  return (
        <RegisteredInput 
            register={inputRegister}
            name={name}
            validationOptions={validationOptions}
            onInput={onInput}
            errors={errors}
            inputValue={inputValue}
            inputMode={inputMode}
            {...rest}
        />
  )
}

export default InputWithValidation